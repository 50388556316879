import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/logos/logo_128x128.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  isLoggedIn : boolean = false;
  public stick: boolean = false;

  constructor(
    private authService : AuthService
  ) { }

  ngOnInit(): void {
    this.subs.add(
      this.authService.currentUserValue.subscribe({
        next:(value) => {
          // console.log(value)
          if(value) {
            this.isLoggedIn = true;
          } else {
            this.isLoggedIn = false;
          }
        }
      })
    )
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

  onLogout() {
    this.subs.add(
      this.authService.logout().subscribe()
    )
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
