import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private meta: Meta,
    private title: Title,
    private loader: LoadingBarService, translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'de', 'hr', 'ru']);
    }

    this.meta.addTags([
      {name: 'description', content: 'Your trusted online baby shop in Europe, specializing in high-quality prams, strollers, car seats and furniture for newborns and children from 0-4 years. With personalized advice, find the perfect items for your little ones based on your lifestyle and budget.'},
      {name: 'author', content: 'Newborn'},
      {name: 'keywords', content: 'Newborn, Europe, newborns, prams, strollers, car seats'}
    ]);
    this.setTitle('Newborn');
  }

  public setTitle( newTitle: string) {
    this.title.setTitle( newTitle );
    }
  

  ngOnInit(): void {
    console.log(navigator.language)

  }

}
