import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { ProductService } from "../../../services/product.service";
import { Product } from 'src/app/shared/services/products.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubSink } from 'subsink';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  
  currentLang : string = 'English';

  public ImageSrc : string

  constructor(private productService: ProductService,
    private authService : AuthService) {
  }

  ngOnInit(): void {
    
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.subs.add(
      this.authService.currentLanguage.subscribe({
        next: (value) => {
            this.currentLang = value;
            // console.log(value)
        },
      })
    )
  }

  getProductName() {
    const result = this.product.name.filter(name => name.language === this.currentLang)
    return result[0].value
  }

  getProductDescription() {
    const result = this.product.shortDescription.filter(name => name.language === this.currentLang);
    // console.log(result)
    return result[0].value;
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        })
      }
    })
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    // this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    // this.productService.addToCompare(product);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

}
