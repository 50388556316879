import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, of, retry, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { AuthService } from './auth.service';

import { Product, Variation } from './products.service';

const API_USERS_URL = `${environment.apiUrl}`;

export interface Reserve {
  product : Product,
  variation : Variation,
  qty : number
}
export interface ReserveRequest {
  firstName : string,
  lastName : string,
  email : string,
  phone : string,
  address : string,
  country : string,
  city : string,
  state : string,
  postalCode : string
  reserve : Reserve
}
@Injectable({
  providedIn: 'root',
})
export class ReserveService {
  private reserve$: BehaviorSubject<Reserve> = new BehaviorSubject<Reserve>(null);

  constructor(
    private httpClient : HttpClient,
    private notificationService : NotificationService,
    private authService : AuthService
  ) { } 

  get reserve() {
    return this.reserve$.asObservable();
  }

  setReserve(reserve : Reserve) {
    this.reserve$.next(reserve)
  }


  create(orderRequest: ReserveRequest): Observable<any> {
    this.authService.isLoading$.next(true)
    return this.httpClient.post<any>(`${API_USERS_URL}/reserve/create`, orderRequest).pipe(
      catchError((error : any) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    )
  }

  customErrorMessage () {
    this.notificationService.showNotification('Internal Service Error, Please contact the support service.')
  }

  errorMessageWithError (error: any) {
    this.notificationService.showNotification(error)
  }
  
}
