import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, of, retry, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { PageableRequest } from '../classes/pageable';
import { Page } from '../classes/page';

const API_USERS_URL = `${environment.apiUrl}`;

enum ProductStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}
enum SalesType {
  GENERAL = "GENERAL", 
  ON_SALE = "ON_SALE", 
  NEW_ARRIVAL = "NEW_ARRIVAL"
}
export const SalesTypes = [
  {name : 'General', value : 'GENERAL'},
  {name : 'On Sale', value : 'ON_SALE'},
  {name : 'New Arrival', value : 'NEW_ARRIVAL'},
]
enum StockType {
  IN_STOCK = "IN_STOCK", 
  RESERVE = "RESERVE"
}
export const StockTypes = [
  {name : 'In Stock', value : 'IN_STOCK'},
  {name : 'On Reserve', value : 'RESERVE'}
]

enum Variations {
  COLOR = "COLOR",
  SIZE = "SIZE" ,
  LENGTH = "LENGTH"
}

export interface Variation {
  _id : string,
  sku: string;
  ean: string;
  type: Variations;
  name: [
    {
      language: string;
      value: string;
    }
  ];
  image: {
    key: string;
    originalname: string;
  };
  stock: number;
  price: number;
  salePrice: number;
  status : string
}

export interface Product {
  _id : string;
  index : string;
  sku : string;
  name : [{
      language : string,
      value : string
  }],
  shortDescription : [{
      language : string,
      value : string
  }],
  brand: {
    name : string
  },
  category: string,
  subCategory: string,
  salesType : SalesType,
  stockType : StockType,
  price : number,
  variations : Variation[],
  images : [{ key : string, originalname : string }],
  description: [{
      language : string,
      value : string
  }],
  descriptionImage: {
      key : string, 
      originalname : string
  },
  videoLinks: string[], 
  status : ProductStatus
}

export interface cartProduct {
  product : Product,
  variation : Variation,
  qty : number,
  isAvailable : boolean,
  availableStock ?: number,
}
@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private cart$: BehaviorSubject<cartProduct[]> = new BehaviorSubject<cartProduct[]>([]);
  private wishList$: BehaviorSubject<cartProduct[]> = new BehaviorSubject<cartProduct[]>([]);

  constructor(
    private httpClient : HttpClient,
    private notificationService : NotificationService,
    private authService : AuthService
  ) {
    const cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if(cartItems === null) {
      this.cart$.next([]) 
    } else {
      this.cart$.next(cartItems) 
    }
    const wishListItems = JSON.parse(localStorage.getItem('wishListItems'));
    if(wishListItems === null) {
      this.wishList$.next([]) 
    } else {
      this.wishList$.next(wishListItems) 
    }
  } 

  get cart() {
    return this.cart$.asObservable()
  }

  get wishList() {
    return this.wishList$.asObservable()
  }

  public addToCart(cartProduct: cartProduct) {
    const existingCart = this.cart$.value;

    let existingIndex = -1;
    if(existingCart.length > 0) { 
      existingIndex = existingCart.findIndex(item => 
        item.product._id === cartProduct.product._id && item.variation._id === cartProduct.variation._id
      );
    }

    if(existingIndex === -1 ) {
      existingCart.push(cartProduct);
    } else {
      existingCart[existingIndex].qty += cartProduct.qty;
    }
    this.cart$.next([...existingCart]); // Emit the updated cart
    this.notificationService.showNotification('Your product has been added to the Cart!')
    localStorage.setItem("cartItems", JSON.stringify(this.cart$.value));
  }
  
  public removeCartItem(cartProduct: cartProduct) {
    let existingCart = this.cart$.value;
    const index = existingCart.indexOf(cartProduct);
    existingCart.splice(index, 1)
    this.cart$.next([...existingCart]);
    localStorage.setItem("cartItems", JSON.stringify(existingCart));
  }


  public cartSubTotalAmount() : Observable<number> {
    const cartItems = this.cart$.value;
    let cartTotal = 0;
    cartItems.forEach(element => {
      cartTotal += (element.variation.salePrice * element.qty)
    });
    return of(cartTotal);
  }

  public clearCart() {
    this.cart$.next([]);
    localStorage.setItem("cartItems", JSON.stringify([]));
  }

  public cartTotalAmount() : Observable<number> {
    const cartItems = this.cart$.value;
    let cartTotal = 0;
    cartItems.forEach(element => {
      cartTotal += (element.variation.salePrice * element.qty)
    });
    if(cartTotal > 69) {
      return of(cartTotal + 4.9);
    } else {
      return of(cartTotal);
    }
  }

  public updateCartQuantity(cartProduct: cartProduct, quantity: number) {
    let existingCart = this.cart$.value;
    const index = existingCart.indexOf(cartProduct);
    
    const qty = existingCart[index].qty + quantity
    const existingStock = existingCart[index].variation.stock

    if(qty > existingStock) {
      this.notificationService.showNotification('You can not add more items than available. In stock '+ existingStock +' items.');
      return;
    }
    existingCart[index].qty = qty;
    this.cart$.next([...existingCart]);
    localStorage.setItem("cartItems", JSON.stringify(existingCart));
  }

  public addToWishList(cartProduct: cartProduct) {

    console.log(cartProduct)
    const existingWishList = this.wishList$.value;

    let existingIndex = -1;
    if(existingWishList.length > 0) { 
      existingIndex = existingWishList.findIndex(item => 
        item.product._id === cartProduct.product._id && item.variation._id === cartProduct.variation._id
      );
    }
    console.log(existingIndex)
    if(existingIndex === -1 ) {
      console.log('push')
      existingWishList.push(cartProduct);
    } else {
      existingWishList[existingIndex].qty = cartProduct.qty;
    }
    console.log(existingWishList)
    this.wishList$.next([...existingWishList]); // Emit the updated cart
    this.notificationService.showNotification('Your product has been added to the wish list!')
    localStorage.setItem("wishListItems", JSON.stringify(this.wishList$.value));
  }
  
  public removeWishListItem(cartProduct: cartProduct) {
    let existingWishList = this.wishList$.value;
    const index = existingWishList.indexOf(cartProduct);
    existingWishList.splice(index, 1)
    this.wishList$.next([...existingWishList]);
    localStorage.setItem("wishListItems", JSON.stringify(existingWishList));
  }

  public updateWishListQuantity(cartProduct: cartProduct, quantity: number) {
    let existingCart = this.cart$.value;
    const index = existingCart.indexOf(cartProduct);
    
    const qty = existingCart[index].qty + quantity
    const existingStock = existingCart[index].variation.stock

    if(qty > existingStock) {
      this.notificationService.showNotification('You can not add more items than available. In stock '+ existingStock +' items.');
      return;
    }
    existingCart[index].qty = qty;
    this.cart$.next([...existingCart]);
    localStorage.setItem("cartItems", JSON.stringify(existingCart));
  }

  page(product : PageableRequest): Observable<any> {
    this.authService.isLoading$.next(true);
    return this.httpClient.post<Page>(`${API_USERS_URL}/product/page`, product).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    )
  }

  topEightProducts() {
    this.authService.isLoading$.next(true);
    return this.httpClient.get<Product[]>(`${API_USERS_URL}/product/exclusive-products`).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    )
  }

  get(id : any): Observable<Product> {
    this.authService.isLoading$.next(true);
    return this.httpClient.get<Product>(`${API_USERS_URL}/product/${id}`).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    )
    
  }
  search(searchString : string): Observable<any> {
    this.authService.isLoading$.next(true);
    return this.httpClient.get<Product[]>(`${API_USERS_URL}/product/search/${searchString}`).pipe(
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.authService.isLoading$.next(false))
    )
    
  }

  customErrorMessage () {
    this.notificationService.showNotification('Internal Service Error, Please contact the support service.')
  }

  errorMessageWithError (error: any) {
    this.notificationService.showNotification(error)
  }
  
}
