import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface User {
  _id: string;
  email : string;
  name : string;
  profileImage : string;
  roles : string[];
  isEmailValidated : boolean;
  resetToken : string;
  resetTokenExpiration : Date;
}

export interface authResponse {
  message : string;
  accessToken: string;
  refreshToken: string;
  user: User;
  profile : any
}

export interface Profile {
  firstName : string,
  lastName : string,
  address : string,
  city : string,
  state : string,
  postalCode : string,
  country : string,
  phone : string,
  purchasedProducts: string,
  isSubscribed: boolean,
  isPastDue: boolean,
  subscription: string,
  stripeCustomerId : string,
  subscriptionId : string,
  latestInvoiceId : string,
  invoicePaymentUrl : string,
  user : User
}

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
  private currentProfile$: BehaviorSubject<Profile> = new BehaviorSubject<Profile>(undefined);

  private currentLanguage$ : BehaviorSubject<string> = new BehaviorSubject<string>('');

  get currentUserValue(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  setCurrentUserValue(user: User) {
    this.currentUser$.next(user);
  }

  get currentProfile(): Observable<Profile> {
    return this.currentProfile$.asObservable();
  }

  setCurrentProfile(profile: Profile) {
    this.currentProfile$.next(profile);
  }

  get currentLanguage(): Observable<string> {
    return this.currentLanguage$.asObservable();
  }

  setCurrentLanguage(language: string) {
    localStorage.setItem('language', JSON.stringify(language));
    this.currentLanguage$.next(language);
  }


  constructor(
    private httpClient : HttpClient,
    private notificationService : NotificationService,
    private router : Router,
    private translate: TranslateService,
  ) {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user)
    this.setCurrentUserValue(user);
    const profile = JSON.parse(localStorage.getItem('profile'));
    this.setCurrentProfile(profile);
    const language = JSON.parse(localStorage.getItem('language'));
    console.log(language)

    if(language === null) {
      switch (navigator.language) {
        case 'en-US':
          // this.translate.use('en');
          this.currentLanguage$.next('ENGLISH')
          break;
        case 'de-DE':
          // this.translate.use('de');
          this.currentLanguage$.next('GERMAN')
          break;
        case 'hrv':
          // this.translate.use('hr');
          this.currentLanguage$.next('CROATIAN')
          break;
          case 'hr':
            // this.translate.use('hr');
            this.currentLanguage$.next('CROATIAN')
          break;
        default:
          break;
      }

      // this.currentLanguage$.next('ENGLISH')
    } else {
      this.currentLanguage$.next(language)
    }
  }

  login(email: string, password: string): Observable<authResponse> {
    this.isLoading$.next(true);
    return this.httpClient.post<authResponse>(`${API_USERS_URL}/auth/login`, {
      email : email, password : password
    }).pipe(
      tap((auth : authResponse) => {
        localStorage.setItem('authToken', JSON.stringify(auth.accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(auth.refreshToken));
        localStorage.setItem('user', JSON.stringify(auth.user));
        localStorage.setItem('profile', JSON.stringify(auth.profile));

        this.setCurrentUserValue(auth.user)
        this.setCurrentProfile(auth.profile)
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(auth.user));
      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }

  forgotPassword(email: string): Observable<authResponse> {
    this.isLoading$.next(true);
    return this.httpClient.post<authResponse>(`${API_USERS_URL}/auth/password-change-request`, {
      email : email
    }).pipe(
      tap((response : any) => {
        if(response.message) {
          this.notificationService.showNotification(response.message);
          this.router.navigateByUrl('/pages/login')
        }
      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }

  changePassword(request: any): Observable<any> {
    console.log(request)
    this.isLoading$.next(true);
    return this.httpClient.post<any>(`${API_USERS_URL}/auth/change-password`, request).pipe(
      tap((response : any) => {
        if(response.message) {
          this.notificationService.showNotification(response.message);
          this.router.navigateByUrl('/pages/login')
        }
      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }

  register(user): Observable<authResponse> {
    this.isLoading$.next(true);
    return this.httpClient.post<authResponse>(`${API_USERS_URL}/auth/register`, user).pipe(
      tap((auth : any) => {
        console.log(auth)
        
      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }
  
  updateProfile(profile : Profile): Observable<Profile> {
    this.isLoading$.next(true);
    return this.httpClient.post<Profile>(`${API_USERS_URL}/auth/update-profile`, profile).pipe(
      tap((response : Profile) => {
        console.log(response)
        this.setCurrentProfile(response);
      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }
  
  getByStripeId(stripeId : string): Observable<any> {
    this.isLoading$.next(true);
    return this.httpClient.get<any>(`${API_USERS_URL}/auth/get-by-stripe/${stripeId}`).pipe(
      tap((auth : any) => {
        console.log(auth)
        
      }),
      catchError((error) => {
        console.log('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => this.isLoading$.next(false))
    )
    
  }

  
  getAuthFromLocalStorage(): any {
    try {
      const lsValue = localStorage.getItem('authToken');
      if (!lsValue) {
        return undefined;
      }
      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  
  logout(): Observable<any> {
    return this.httpClient.post<any>(`${API_USERS_URL}/auth/logout`, null).pipe(
      tap(res => {
        console.log(res)
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('profile');

        this.setCurrentUserValue(null)
        this.setCurrentUserValue(null)
        this.router.navigate(['/pages/login'], {
          queryParams: {},
        });
      }),
      catchError((error)=> {
        console.error('err', error);
        if(error.error.message) {
          this.errorMessageWithError(error.error.message)
        } else {
          this.customErrorMessage();
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.isLoading$.next(false)
      })
    );
  }

  customErrorMessage () {
    this.notificationService.showNotification('Internal Service Error, Please contact the support service.')
  }

  errorMessageWithError (error: any) {
    this.notificationService.showNotification(error)
  }
  
}
