import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationService } from '../services/notification.service';

const TOKEN_HEADER_KEY = 'x-access-token';
const REFRESH_TOKEN_HEADER_KEY = 'x-refresh-token';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(private authService : AuthService,
      private jwtHelper :  JwtHelperService,
      private notificationService : NotificationService,
      private router : Router) {}

    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {

      if (httpRequest.url.includes(`${API_USERS_URL}/auth/login`)){
          return httpHandler.handle(httpRequest);
      }
      if (httpRequest.url.includes(`${API_USERS_URL}/auth/register`)){
          return httpHandler.handle(httpRequest);
      }
      if (httpRequest.url.includes(`${API_USERS_URL}/auth/logout`)){
          return httpHandler.handle(httpRequest);
      }

      let authReq = httpRequest;
      const authToken : any = localStorage.getItem('authToken')
      const refreshToken : any = localStorage.getItem('refreshToken')
      const _token = JSON.parse(authToken)
      const _refreshToken = JSON.parse(refreshToken)

      if (_token != null && _refreshToken != null) {
        authReq = this.addTokenHeader(httpRequest, _token, _refreshToken);
      }

      return httpHandler.handle(authReq).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const accessToken = event.headers.get(TOKEN_HEADER_KEY);
            const refreshToken = event.headers.get(REFRESH_TOKEN_HEADER_KEY);
            if (accessToken && refreshToken) {
              localStorage.setItem('authToken', JSON.stringify(accessToken));
              localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
            }
          }
        }, (error: any) => {
          console.log(error)
          console.log(error instanceof HttpErrorResponse)
          if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                  return this.handle401Error(authReq, httpHandler);
              }
          }
          return throwError(() => error);
        })
      );
    }

    private addTokenHeader(request: HttpRequest<any>, token: any, refreshToken : any) {
      const headers = new HttpHeaders().set(TOKEN_HEADER_KEY, token)
      .set(REFRESH_TOKEN_HEADER_KEY, refreshToken);
      return request.clone({ headers: headers });
    }

    private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        this.authService.logout().subscribe();
        this.notificationService.showNotification('Session has expired!, please login to the system.')
    }
}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ];
