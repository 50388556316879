<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="ImageSrc ? ImageSrc : product.variations[selectedVariationIndex].image.key" [alt]="product.variations[selectedVariationIndex].image.originalname" class="img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ getProductName() }}</h2>
                        <h3>{{ product.variations[selectedVariationIndex].price | currency:'EUR' }}
                            <del *ngIf="product.variations[selectedVariationIndex].salePrice && (product.variations[selectedVariationIndex].salePrice !== product.variations[selectedVariationIndex].price)"><span class="money">{{ product.variations[selectedVariationIndex].salePrice | currency:'EUR' }}</span></del>
                        </h3>
                        <div class="border-product">
                            <h6 class="product-title">{{'QUICK_VIEW_PRODUCT_DETAILS' | translate}}</h6>
                            <p>{{ getProductDescription().substring(0, 200)+'...' }}</p>
                        </div>
                        <div class="product-description border-product">
                            <div class="size-box">
                                <ul>
                                    <li *ngFor="let _variant of product.variations; let i = index" [ngClass]="{'active' : _variant.name === selectedVariation.name}">
                                        <a (click)="selectVariation(_variant, i)">{{ _variant.name | translate }}</a>
                                    </li>
                                </ul>
                            </div>
                            <ng-container *ngIf="this.product.stockType !== 'RESERVE'">
                                <h5 class="avalibility" *ngIf="this.selectedVariation.stock >= this.counter"><span>{{'QUICK_VIEW_IN_STOCK' | translate}}</span></h5>
                                <h5 class="avalibility" *ngIf="this.selectedVariation.stock < this.counter"><span>{{'QUICK_VIEW_OUT_OF_STOCK' | translate}}</span></h5>
                            </ng-container>
                            <h5 class="avalibility" *ngIf="this.product.stockType === 'RESERVE'"><span>{{'QUICK_VIEW_RESERVE' | translate}}</span></h5>
                            <h6 class="product-title">{{'QUICK_VIEW_QTY' | translate}}</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons d-flex flex-row gap-3" *ngIf="this.product.stockType !== 'RESERVE'">
                            <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > selectedVariation.stock" (click)="addToCart(product)">{{'QUICK_VIEW_ADD_TO_CART' | translate }}</a>
                            <a [routerLink]="['/shop/product/', product._id]" class="btn btn-solid">{{'QUICK_VIEW_VIEW_DETAILS' | translate}}</a>
                        </div>
                        <div class="product-buttons d-flex flex-row gap-3" *ngIf="this.product.stockType === 'RESERVE'">
                            <a class="btn btn-solid" (click)="onReserve()">{{'QUICK_VIEW_RESERVE_BUTTON_TEXT' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>