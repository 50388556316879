<!--footer section -->
<footer [class]="class">
	<!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From Multikart By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6 mb-2">
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>{{"FOOTER_SLOGAN" | translate}}</p>
						<div class="footer-shipping">
                            <ul>
                                <li>
                                    <img src="./assets/images/shipping/dhl.png" alt="dhl">
                                </li>
                                <li>
									<img src="./assets/images/shipping/post_austria.png" alt="post_austria">
                                </li>
                                <li>
									<img src="./assets/images/shipping/gls.png" alt="gls">
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1 mb-2">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{'FOOTER_TITLE_1' | translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a [routerLink]="['/shop/collection']" [queryParams]="{category : 'Prams & Strollers'}">{{"Prams & Strollers" | translate}}</a></li>
                                <li><a [routerLink]="['/shop/collection']" [queryParams]="{category : 'Buggies'}">{{"Buggies" | translate}}</a></li>
                                <li><a [routerLink]="['/shop/collection']" [queryParams]="{category : 'Car Seats'}">{{"Car Seats" | translate}}</a></li>
                                <li><a [routerLink]="['/shop/collection']" [queryParams]="{category : 'Home'}">{{"Home" | translate}}</a></li>
                                <li><a [routerLink]="['/shop/collection']" [queryParams]="{category : 'Accessories'}">{{"Accessories" | translate}}</a></li>

							</ul>
                        </div>
					</div>
				</div>
				<div class="col mb-2">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>{{'FOOTER_TITLE_2' | translate}}</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a routerLink="/pages/aboutus">{{'FOOTER_LINK_1' | translate}}</a></li>
                                <li><a href="javascript:void(0)">{{'FOOTER_LINK_2' | translate}}</a></li>
                                <!-- <li><a href="javascript:void(0)">{{'FOOTER_LINK_3' | translate}}</a></li> -->
                                <li><a routerLink="/pages/terms-and-conditions">{{'FOOTER_LINK_4' | translate}}</a></li>
                                <li><a routerLink="/pages/privacy-policy">{{'FOOTER_LINK_5' | translate}}</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col mb-2">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>{{'FOOTER_TITLE_3' | translate}}</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <!-- <li><i class="fa fa-map-marker"></i>Multikart Demo Store, Demo store
						            India 345-659</li> -->
						        <!-- <li><i class="fa fa-phone"></i>{{'FOOTER_TEXT_1' | translate}}123-456-7898</li> -->
						        <li><i class="fa fa-envelope-o"></i>{{'FOOTER_TEXT_2' | translate}}<a>{{'Support@newborn.company'}}</a></li>
						        <!-- <li><i class="fa fa-fax"></i>Fax: 123456</li> -->
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} powered by Newborn</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <!-- <a><img src="assets/images/icon/paypal.png" alt=""></a> -->
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->