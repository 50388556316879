import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  public themeLogo: string = 'assets/images/logos/logo_128x128.png'; // Change Logo

  constructor() { }

  ngOnInit(): void {
  }

}
