import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { SubSink } from 'subsink';
import { Product, ProductsService, cartProduct } from '../../services/products.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  private subs = new SubSink();

  public cartProducts: cartProduct[] = [];
  public search: boolean = false;
  
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'German',
    code: 'de'
  }, {
    name: 'Croatian',
    code: 'hr'
  }];

  searchString: string;

  currentLang : string = 'English';
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private authService : AuthService,
    private router : Router,
    private productsService : ProductsService) {
    
  }

  ngOnInit(): void {
    this.subs.add(
      this.authService.currentLanguage.subscribe({
        next: (value) => {
            this.currentLang = value;
            console.log(value)
            switch (this.currentLang) {
              case 'ENGLISH':
                this.translate.use('en');
                break;
              case 'GERMAN':
                this.translate.use('de');
                break;
              case 'RUSSIAN':
                this.translate.use('ru');
                break;
              case 'CROATIAN':
                this.translate.use('hr');
                break;
              default:
                break;
            }
        },
      })
    );
    this.subs.add(
      this.productsService.cart.subscribe({
        next:(value) => {
          console.log(value)
          this.cartProducts = value;
        },
      })
    )
  }

  searchToggle(){
    this.search = !this.search;
  }

  onSearch() {
    console.log(this.searchString);
    this.router.navigateByUrl(`/shop/collection?search=${this.searchString}`);
  }

  get getTotal(): Observable<number> {
    return this.productsService.cartTotalAmount();
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
      switch (code) {
        case 'en':
          this.authService.setCurrentLanguage('ENGLISH')
          break;
        case 'de':
          this.authService.setCurrentLanguage('GERMAN')
          break;
        case 'ru':
          this.authService.setCurrentLanguage('RUSSIAN')
          break;
        case 'hr':
          this.authService.setCurrentLanguage('CROATIAN')
          break;
      
        default:
          break;
      }

    }
  }

  getProductName(product : Product) {
    const result = product.name.filter(name => name.language === this.currentLang)
    return result[0].value
  }

  removeItem(product: any) {
    this.productsService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    // this.productService.Currency = currency
  }

}
